import React from 'react';
import PopupTrigger from '../components/PopupTrigger';
import SEO from '../components/Seo';

export default function CostsPage() {
  return (
    <>
      <SEO
        title="Costs"
        description="At the Augustine Institute Graduate School of Theology, we believe in a “no hidden fees” approach to billing."
      />
      <main className="page-costs">
        <section className="container section-m-t page-m-b">
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              <h2>Costs</h2>
              <div className="content-columns">
                <div className="content-columns__row">
                  <div className="content-columns__col">
                    <p>
                      At the Augustine Institute Graduate School of Theology, we
                      believe in a “no hidden fees” approach to billing. This
                      means,
                    </p>
                    <ul className="bullets">
                      <li>
                        <strong>
                          A flat rate for tuition, billed once per term.
                        </strong>
                      </li>
                      <li>
                        <strong>
                          No additional charges for new student fees, technology
                          fees, on-campus activities, or graduation.
                        </strong>
                      </li>
                      <li>
                        <strong>No “leave of absence” fees.</strong>
                      </li>
                    </ul>
                    <p>
                      With the aim of full transparency, the Augustine Institute
                      seeks to simplify our presentation of the cost of tuition.
                      The only other expense associated with coursework is the
                      cost of textbooks.
                    </p>
                  </div>
                  <div className="content-columns__col">
                    <PopupTrigger title="Courses">
                      <h3 className="h5">Courses</h3>
                      <p>Cost per Course:</p>
                      <ul className="bullets">
                        <li>Credit-Seeking - $1,800</li>
                        <li>Audit - $400</li>
                      </ul>
                    </PopupTrigger>
                    <PopupTrigger title="Degree">
                      <h3 className="h5">Degree</h3>
                      <p>
                        Distance Education and On-Campus students (non-audit)
                        can choose to pay their semester tuition at the
                        beginning of each term or opt to use a semester long
                        payment plan for a small fee.
                      </p>
                    </PopupTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
